.flex-container {
    width: 100%;
    height: 100%;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

/* body {
    background-color: #eee;
} */

.unit {
    text-align: center;
}

.heart {
    position: relative;
    width: 114px;
}

[class*="heart-piece-"] {
    position: absolute;
    top: -5px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
}

.heart-piece-4 {
    -webkit-animation: piece-4 3.2s infinite;
    animation: piece-4 3.2s infinite;
}

.heart-piece-3, .heart-piece-5 {
    -webkit-animation: piece-3 3.2s infinite;
    animation: piece-3 3.2s infinite;
}

.heart-piece-2, .heart-piece-6 {
    -webkit-animation: piece-2 3.2s infinite;
    animation: piece-2 3.2s infinite;
}

.heart-piece-1, .heart-piece-7 {
    -webkit-animation: piece-1 3.2s infinite;
    animation: piece-1 3.2s infinite;
}

.heart-piece-0, .heart-piece-8 {
    -webkit-animation: piece-0 3.2s infinite;
    animation: piece-0 3.2s infinite;
}

.heart-piece-0 {
    left: 0;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    background-color: #d35145;
}

.heart-piece-1 {
    left: 13px;
    -webkit-animation-delay: 0.15s;
    animation-delay: 0.15s;
    background-color: #ffb238;
}

.heart-piece-2 {
    left: 26px;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
    background-color: #fbdb4a;
}

.heart-piece-3 {
    left: 39px;
    -webkit-animation-delay: 0.45s;
    animation-delay: 0.45s;
    background-color: #b8d5b8;
}

.heart-piece-4 {
    left: 52px;
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
    background-color: #4d94d3;
}

.heart-piece-5 {
    left: 65px;
    -webkit-animation-delay: 0.75s;
    animation-delay: 0.75s;
    background-color: #fbd1a2;
}

.heart-piece-6 {
    left: 78px;
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
    background-color: #d35145;
}

.heart-piece-7 {
    left: 91px;
    -webkit-animation-delay: 1.05s;
    animation-delay: 1.05s;
    background-color: #ffb238;
}

.heart-piece-8 {
    left: 104px;
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s;
    background-color: #fbdb4a;
}

@-webkit-keyframes piece-4 {
    0%, 10%, 90%, 100% {
        height: 10px;
        top: -5px;
    }
    45%, 55% {
        height: 94px;
        top: -23px;
    }
}

@keyframes piece-4 {
    0%, 10%, 90%, 100% {
        height: 10px;
        top: -5px;
    }
    45%, 55% {
        height: 94px;
        top: -23px;
    }
}

@-webkit-keyframes piece-3 {
    0%, 10%, 90%, 100% {
        height: 10px;
        top: -5px;
    }
    45%, 55% {
        height: 90px;
        top: -31px;
    }
}

@keyframes piece-3 {
    0%, 10%, 90%, 100% {
        height: 10px;
        top: -5px;
    }
    45%, 55% {
        height: 90px;
        top: -31px;
    }
}

@-webkit-keyframes piece-2 {
    0%, 10%, 90%, 100% {
        height: 10px;
        top: -5px;
    }
    45%, 55% {
        height: 80px;
        top: -37px;
    }
}

@keyframes piece-2 {
    0%, 10%, 90%, 100% {
        height: 10px;
        top: -5px;
    }
    45%, 55% {
        height: 80px;
        top: -37px;
    }
}

@-webkit-keyframes piece-1 {
    0%, 10%, 90%, 100% {
        height: 10px;
        top: -5px;
    }
    45%, 55% {
        height: 60px;
        top: -31px;
    }
}

@keyframes piece-1 {
    0%, 10%, 90%, 100% {
        height: 10px;
        top: -5px;
    }
    45%, 55% {
        height: 60px;
        top: -31px;
    }
}

@-webkit-keyframes piece-0 {
    0%, 10%, 90%, 100% {
        height: 10px;
        top: -5px;
    }
    45%, 55% {
        height: 30px;
        top: -15px;
    }
}

@keyframes piece-0 {
    0%, 10%, 90%, 100% {
        height: 10px;
        top: -5px;
    }
    45%, 55% {
        height: 30px;
        top: -15px;
    }
}
